html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.show > .nav-link,
.active > .nav-link,
.nav-link.show,
.nav-link.active {
  //font-weight: bold;
}

body {
  margin: 0;
  font-family: "Rubik Medium", "Rubik bold", "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a,
a:active,
a:hover,
a:visited {
  text-decoration: none;
}

a {
  color: var(--blundee_color_thin);
}
