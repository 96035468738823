.input-range--disabled .input-range__slider {
  background: var(--blundee_input_range_background) !important;
  border: 1px solid var(--blundee_input_range_border_color) !important;
}

.input-range__track {
  background: rgb(33, 33, 33) !important;
}

.input-range__slider {
  background: var(--blundee_input_range_background) !important;
  border: none !important;
}

.input-range__slider-container,
.input-range__slider,
.input-range__track {
  transition: none !important;
  //transition-duration: 100ms !important;
}

.input-range__track--active {
  background: var(--blundee_input_range_background) !important;
}
