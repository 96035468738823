// Other custom scss
@import "./fonts.scss";
@import "./general.scss";
@import "./slider.scss";
@import "./multiselect.scss";
@import "./input-range.scss";
@import "./ion-toast-container.scss";

#safeareas {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  margin: 0;
  height: 0;
  width: 0;
  z-index: -1000000;
  visibility: hidden;
}

:root, html, * {
  --asd: 30;
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

.pigeon-attribution {
  display: none;
}

:root {
  --blundee_link_color: var(--blundee_color_thin);
  --blundee_error: #de1e09;
  --blunde_loading_bg: rgba(47, 47, 47, 0.45);
  --blundee_yellow: #ffc641;
  --blundee_yellow_hover: #ffca54;
  --blundee_yellow_active: #ffcf59;
  --blundee_turkiz: #4aa997;
  --blundee_grey: #444440;
  --blundee_grey_dark: #1f1f1d;
  --blundee_turkiz_hover: #4aa997;
  --blundee_turkiz_active: #4aa997; //#ffef42

  --blundee_card_selection: #ffc641;
  --cart_mobil_opened_text: #19213d;
  --cart_mobile_opened_fill: #ffffff;
  --cart_mobile_closed_fill: #ffc641;
  --blundee_scroll_thumb_color: #ffc641;
  --blundee_navbar_bg_color: #ffc641;
  --blundee_background: #fafafa;
  --blundee_background_light: #ffffff;
  --blundee_background_lighter: #fafafa;
  --blundee_background_card_1: #ffffff;
  --blundee_background_card_2: #fcfcfc;
  --blundee_background_card_3: #f1f1f1;
  --blundee_background_card_4: #f1f1f1;
  --blundee_background_secondary: #19213d;
  --blundee_background_secondary_2: #17191e;
  --blundee_background_restaurant_card_special_1: #ffc641;
  --blundee_background_restaurant_card_special_2: #ffc641;
  --blundee_extra_selection_bg: rgba(255, 198, 65, 0.35);
  --blundee_extra_selection_bg_wrong: rgba(232, 66, 66, 0.3);
  --blundee_price_bg: rgba(255, 198, 65, 0.35);
  --blundee_special_price_bg: rgba(232, 66, 66, 1);
  --blundee_special_price_color: #ffffff;
  --blundee_special_price_crossing: rgba(255, 0, 0, 0.5);
  --blundee_product_selected_bg1: #ff9100;
  --blundee_product_selected_bg2: #ffc648;
  --blundee_restaurant_note_background: var(--blundee_background_card_2);
  --blundee_border_color_1: #19213D80;
  --blundee_border_color_2: #19213d;
  --blundee_color_normal: #19213d;
  --blundee_color_thin: #696e81;
  --blundee_color_tag: #c1c1c2;
  --blundee_color_disabled: #696e81;
  --blundee_color_2: #ffffff;
  --blundee_button_bg_color: #ffc641;
  --blundee_tag_bg_color: #ffc641;
  --blundee_button_disabled_bg: #d0d0d0;
  --blundee_button_disabled_color: #4f4f4f;
  --blundee_color_nav_icon: #19213d;
  --blundee_color_nav_icon_selected: #2b3762;
  --blundee_bg_color_nav_icon_selected: #ffc641;
  --blundee_toast_progress_color: #ffc641;
  --blundee_cookies_bg_color: #f5f5f5;
  --blundee_cookies_color: var(--blundee_color_normal);
  --blundee_cookies_button_bg_color: #ffc641;
  --blundee_cookies_button_color: var(--blundee_color_normal);
  --blundee_homepage_button_bg1: rgba(211, 10, 0, 0.6);
  --blundee_homepage_button_bg2: #D30A00;

  --blundee_shadow: 0 2px 3px 0 #9b9b9b;
  --blundee_start_page_person_shadow: 0 0 5px 0 #b2b2b2;
  --blundee_card_shadow: 0 0px 1px 0 #9b9b9b;
  --blundee_popup_shadow: 0 0 10px 0 #9b9b9b;
  --blundee_button_shadow: 0 0 3px 0 #ababab;
  --blundee_product_card_shadow: 0 0 1px 0 #eaeaea;

  --blundee_font_bold: "Rubik Bold", sans-serif;
  --blundee_font_medium: "Rubik Medium", sans-serif;


  --blundee_input_bg_1: #ffffff;
  --blundee_input_bg_2: #ffffff;
  --blundee_input_bg_3: #ffffff;
  --blundee_input_color_2: #19213d;

  --blundee_text_color: #f1f1f1;
  --blundee_text_color_1: #5a5a5a;
  --blundee_text_color_2: #363636;
  --blundee_text_color_3: #444440;
  --blundee_text_color_4: #808080;
  --blundee_text_color_disabled: #606060;

  --reservation_page_map_controls_bg: rgba(0, 0, 0, 0.8);
  --reservation_page_map_full_overlay_bg: rgba(76, 76, 76, 0.3);

  --blundee_input_range_background: #ffc641;
  --blundee_input_range_border_color: #ffc641;
  --blundee_app_loading_dots_background: #ffc641;
  --loading_dots_1: #ffcc62;
  --loading_dots_2: #ffcb58;
  --loading_dots_3: #ffc94b;
  --loading_dots_4: #ffc641;
  --loading_dots_5: #e5b137;
  --loading_dots_6: #d9a429;
}

button{
  font-family: "Rubik Medium", "Rubik bold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.rmdp-container {
  .input_range_asdasd {
    background-color: transparent;
    color: var(--blundee_turkiz);
    border: none;
    min-width: auto;
    margin-left: 6px;
    font-size: 13pt;
    font-weight: bold;
    height: 35px;
    padding: 0;
    width: 200px;

    @media screen and (max-width: 800px) {
      width: 100%;
    }

    &:active, &:focus {
      outline: none;
      border: none;
    }
  }
}

.appLoadingDots {
  width: 200px;

  & > ._index__dotsText {
    margin-top: 24px;
    color: var(--blundee_app_loading_dots_background);
    font-size: 15pt;
    font-weight: bold;
  }
}
