.slider-bullet {
  background-color: var(--blundee_button_bg_color);
  transition: background-color 100ms ease-in-out, opacity 100ms ease-in-out;
}

.swiper-wrapper.disabled {
  transform: translate3d(0px, 0, 0) !important;
}

.swiper-pagination.disabled {
  display: none;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--blundee_button_bg_color);
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.swiper {
  overflow: unset;
}