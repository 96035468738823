.multi-select {
  --rmsc-main: var(--blundee_background) !important;
  --rmsc-hover: var(--blundee_background) !important;
  --rmsc-selected: var(--blundee_background_light) !important;
  --rmsc-border: var(--blundee_background) !important;
  --rmsc-gray: var(--blundee_button_disabled_bg) !important;
  --rmsc-bg: var(--blundee_background) !important;
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 6px !important; /* Radius */
  --rmsc-h: 40px !important; /* Height */
}

.dropdown-container{
  z-index: 999;
}
